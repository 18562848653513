import Button from '../../../../components/button/Button';
import './Saas.scss';
import SaasBgMobile from '../../../../assets/images/saas-bg-mobile.png';
import SaasBg from '../../../../assets/images/saas-bg.png';
import { HashLink } from 'react-router-hash-link';

const Saas = () => {

    return (
        <div className="saas dark-bg relative py-[103px] px-[68px] lg:py-[140px]">
            <div className='flex flex-col gap-7 lg:gap-12 items-center'>
                <span className='text'>Looyti ile sadakat,
                    SaaS ile uçtan uca!</span>
                <HashLink to={'/detail/#saas'}><Button color='#FFFFFF' maxWidth={'max-w-[198px] lg:max-w-[209px]'} arrowColor='#141414' textColor={'text-[#141414]'} text='Detayları İnceleyin' /></HashLink>
            </div>
            <img alt='SaasBg' className='bg hidden lg:block' src={SaasBg} />
            <img alt='SaasBgMobile' className='bg lg:hidden' src={SaasBgMobile} />
        </div>
    );
}

export default Saas;