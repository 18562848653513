import { useTranslation } from 'react-i18next';
import './We.scss';
import TerminalEffect from '../../../../components/terminal/TerminalEffect';
import LeftTitle from '../../../../components/leftTitle/LeftTitle';



const We = ({ref}) => {

    const { t } = useTranslation();


    return (
        <div id='we' className="we light-bg">
            <div className='flex flex-col gap-6 lg:gap-[72px]'>
                <div className='flex flex-col gap-6 lg:gap-[60px]'>
                    <LeftTitle text={t("HOME.WE.LEFT")} />
                    <span className='title' dangerouslySetInnerHTML={{ __html: t("HOME.WE.TITLE") }}></span>
                </div>
                <div className='flex flex-col lg:flex-row gap-12 lg:gap-9 justify-between 2xl:justify-around'>
                    <div className='order-2 lg:order-1 w-full max-w-[659px] m-auto'>
                        <TerminalEffect />
                    </div>
                    <div className='flex flex-col text gap-7 lg:gap-9 order-1 lg:order-2'>
                        <span>{t("HOME.WE.TEXT")}</span>
                        <span>{t("HOME.WE.TEXT2")}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default We;