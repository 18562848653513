import { HashLink } from 'react-router-hash-link';
import Button from '../button/Button';
import './Card.scss';

const Card = ({ title, description, image, to, delay }) => {
    return (
        <div className="card">
            <img className={`card-image floating ${delay}`} src={image} alt={title} />
            <span className='card-title'>{title}</span>
            <span className='card-desc'>{description}</span>
            <HashLink className='mt-auto w-fit' to={to}><Button text='Detayları İnceleyin' maxWidth='max-w-[198px] lg:max-w-[215px]' /></HashLink>
        </div>
    );
}

export default Card;