import './Hero.scss';
import Bg from '../../../../assets/images/deneme.png';
import BgMobile from '../../../../assets/images/deneme-mobile.png';
import Button from '../../../../components/button/Button';
import { ReactComponent as Circle } from '../../../../assets/images/circle.svg';
import { ReactComponent as CircleMobile } from '../../../../assets/images/circle-mobile.svg';
import { HashLink } from 'react-router-hash-link';

const Hero = () => {

    return (
        <>
            <div className="hero dark-bg">
                <Circle className='circle hidden lg:block circle-turn delay-3s opacity-0' />
                <CircleMobile className='circle block lg:hidden circle-turn delay-3s opacity-0' />
                <img alt='Bg' className='hero-bg hidden lg:block hero-scale' src={Bg} />
                <img alt='BgMobile' className='hero-bg lg:hidden hero-scale' src={BgMobile} />
                <div className='flex flex-col z-50 gap-5 relative max-w-[300px] lg:max-w-[605px] m-auto top-[42.5%] lg:top-[55%] lg:gap-8 items-center hero-content-appear delay-2s opacity-0'>
                    <div className='flex flex-col'>
                        <span className='title'>Müşteri sadakatinde</span>
                        <span className='title flex m-auto'><span className='text-[#FB792E] italic'>yenilikçi&nbsp;</span> yaklaşım</span>
                    </div>
                    <div className='flex flex-col mb-2 lg:mb-0'>
                        <span className='text'>Sadakatten çok daha ötesi,</span>
                        <span className='text'>stratejik yatırımın ta kendisi.</span>
                    </div>
                    <HashLink to={'/#we'}><Button color='#FFFFFF' maxWidth={'lg:max-w-[209px]'} arrowColor='red' textColor={'text-[#141414]'} text='Looyti’yi Keşfedin' /></HashLink>
                </div>
            </div>
        </>
    );
}

export default Hero;