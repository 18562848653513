import './Button.scss';
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg';

const Button = ({ text, maxWidth, color, textColor, onClick, type }) => {
    

    return (
        <button type={type} onClick={onClick} style={{backgroundColor: color}} className={`button-comp z-50 ${maxWidth} ${textColor}`}>
            {text}
            <Arrow className='arrow' />
        </button>
    );
}

export default Button;