import './Services.scss';
import Card from '../../../../components/card/Card';
import { useTranslation } from 'react-i18next';
import Card1 from '../../../../assets/images/card-1.png';
import Card2 from '../../../../assets/images/card-2.png';
import Card3 from '../../../../assets/images/card-3.png';
import Card4 from '../../../../assets/images/card-4.png';
import Card5 from '../../../../assets/images/card-5.png';
import { ReactComponent as Bow } from '../../../../assets/images/bow-bg.svg';
import LeftTitle from '../../../../components/leftTitle/LeftTitle';


const Services = () => {

    const { t } = useTranslation();

    return (
        <div id={'services'} className="services dark-bg">
            <LeftTitle text={t("HOME.SERVICES.LEFT")} color='rgba(255, 255, 255, 1)' />
            <span className='title'>{t("HOME.SERVICES.TITLE")}</span>
            <div className='services-grid'>
                <Card to={'/detail/#card1'} title={t("HOME.SERVICES.CARD.TITLE")} description={t("HOME.SERVICES.CARD.TEXT")} image={Card1} link={'d/eneme'} />
                <Card delay={'delay-1s'} to={'/detail/#card2'} title={t("HOME.SERVICES.CARD2.TITLE")} description={t("HOME.SERVICES.CARD2.TEXT")} image={Card2} link={'d/eneme'} />
                <Card to={'/detail/#card3'} title={t("HOME.SERVICES.CARD3.TITLE")} description={t("HOME.SERVICES.CARD3.TEXT")} image={Card3} link={'d/eneme'} />
                <Card delay={'delay-1s'} to={'/detail/#card4'} title={t("HOME.SERVICES.CARD4.TITLE")} description={t("HOME.SERVICES.CARD4.TEXT")} image={Card4} link={'d/eneme'} />
                <Card to={'/detail/#card5'} title={t("HOME.SERVICES.CARD5.TITLE")} description={t("HOME.SERVICES.CARD5.TEXT")} image={Card5} link={'d/eneme'} />
                <Bow className='bow bow-float' />
            </div>
        </div >
    );
}

export default Services;