import React, { useEffect, useState, useRef, useMemo } from 'react';
import './TerminalEffect.scss';

const TerminalEffect = () => {
  const commands = useMemo(() => [
    'echo "Initializing setup..."',
    'sudo apt-get update && sudo apt-get upgrade -y',
    'sudo apt-get install -y nodejs npm git',
    'node -v && npm -v',
    'echo "Cloning repository from GitHub..."',
    'git clone https://github.com/CovenLabs/looyti.git',
    'cd looyti',
    'echo "Installing dependencies..."',
    'npm install',
    'echo "Building the project..."',
    'npm run build',
    'echo "Running tests..."',
    'npm test',
    'echo "Starting the server..."',
    'npm start',
    'Server running at http://localhost:3000/',
    'curl http://localhost:3000/',
    'GET / 200 15ms',
    'POST /api/resource 201 35ms',
    'GET /api/resource 200 10ms',
    'echo "Fetching data from API..."',
    'curl -X GET "https://api.example.com/resource" -H "accept: application/json"',
    'echo "Updating server configuration..."',
    'sudo systemctl restart server',
    'echo "Deployment complete!"',
    'echo "Shutting down the server..."',
    'npm stop',
    'sudo shutdown -h now',
    'echo "Setup finished."',
    'echo "<parse_json data>"',
    'uuid: 550e8400-e29b-41d4-a716-446655440000',
    'echo "This sample shows how to set the <c>ID</c> field."',
    'echo "<code>"',
    'echo "Dim responseStatus"',
    'echo "</code>"',
  ], []);

  const [terminalText, setTerminalText] = useState('');
  const [commandIndex, setCommandIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const terminalRef = useRef(null);

  useEffect(() => {
    const type = () => {
      const currentCommand = commands[commandIndex];
      if (charIndex < currentCommand.length) {
        setTerminalText(prev => prev + currentCommand[charIndex]);
        setCharIndex(charIndex + 1);
      } else {
        setTerminalText(prev => prev + '\n');
        setCommandIndex((commandIndex + 1) % commands.length);
        setCharIndex(0);
      }
    };

    const timeout = setTimeout(type, 50);

    return () => clearTimeout(timeout);
  }, [charIndex, commandIndex, commands]);

  useEffect(() => {
    if (terminalRef.current) {
      if (terminalRef.current.scrollHeight > terminalRef.current.clientHeight) {
        // If overflow, reset the terminal
        setTerminalText('');
        setCommandIndex((commandIndex + 1) % commands.length);
        setCharIndex(0);
      } else {
        terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
      }
    }
  }, [terminalText, commandIndex, commands.length]);

  return (
    <div className="terminal-container">
      <div className="terminal-content" ref={terminalRef}>
        {terminalText}
      </div>
    </div>
  );
};

export default TerminalEffect;
