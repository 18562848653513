import './Home.scss';
import Hero from './sections/hero/Hero';
import We from './sections/we/We';
import Services from './sections/services/Services';
import Saas from './sections/saas/Saas';

const Home = () => {
    
    return (
        <div className="home">
            <Hero />
            <We />
            <Services />
            <Saas />
        </div>
    );
};

export default Home;