import './Journey.scss';
import Button from '../../../../components/button/Button';
import LeftMobile from '../../../../assets/images/journey-left-mobile.png';
import RightMobile from '../../../../assets/images/journey-right-mobile.png';
import Left from '../../../../assets/images/journey-left.png';
import Right from '../../../../assets/images/journey-right.png';
import { openModal } from '../../../../features/formState/FormState';
import { useDispatch } from 'react-redux';

const Journey = () => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(openModal());
    };

    return (
        <div className="journey dark-bg">
            <div className='flex flex-col items-center absolute left-0 right-0 bottom-0 top-0 m-auto h-min lg:max-w-[529px] lg:items-start lg:mr-[174px]'>
                <span className='title'>Looyti ile sadakat</span>
                <span className='title pb-7 lg:pb-10'>yolculuğuna başlayın</span>
                <Button onClick={handleClick} text='Bize Ulaşın' maxWidth='max-w-[151px] lg:max-w-[187px]' />
            </div>
            <img src={LeftMobile} alt="left-mobile" className='absolute left-0 bottom-0 block xl:hidden rounded-b-[24px]' />
            <img src={RightMobile} alt="right-mobile" className='absolute right-0 bottom-0 block xl:hidden rounded-b-[24px]' />
            <img src={Left} alt="left-mobile" className='absolute left-0 bottom-0 hidden xl:block rounded-b-[48px]' />
            <img src={Right} alt="right-mobile" className='absolute right-0 bottom-0 hidden xl:block rounded-b-[48px]' />
        </div>
    );
}

export default Journey;