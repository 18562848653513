import FormModal from "./components/modal/FormModal";
import Footer from "./layout/footer/Footer";
import Header from "./layout/header/Header";
import Detail from "./pages/detail/Detail";
import Home from "./pages/home/Home";
import { Route, Routes, useLocation } from "react-router-dom";


const routesConfig = [

    {
        path: '/',
        element: <Home />,
    },
    {
        path: '/detail',
        element: <Detail />,
    }
]

const RouteComponent = () => {
    const location = useLocation();

    return (
        <>
            <Header />
            <FormModal />
            <Routes location={location}>
                {routesConfig.map((route, i) => (
                    <Route key={i} path={route.path} element={route.element} />
                ))}
            </Routes>
            <Footer />
        </>
    );
}

export default RouteComponent;