import './Detail.scss';
import Journey from './sections/journey/Journey';
import ServiceExtended from './sections/serviceExtended/ServiceExtended';
import Technology from './sections/technology/Technology';

const Detail = () => {

    return (
        <div className="detail">
            <Journey />
            <ServiceExtended />
            <Technology />
        </div>
    );
}

export default Detail;