import './Footer.scss';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
// import { ReactComponent as Linkedin } from '../../assets/images/linkedin.svg';
import EmailForm from '../../components/emailform/EmailForm';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { openModal } from '../../features/formState/FormState';

const Footer = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(openModal());
    };

    return (
        <footer className="footer">
            <div className='flex flex-col lg:flex-row gap-6 mb-4 lg:justify-between lg:mb-14'>
                <div className='flex flex-wrap lg:flex-nowrap lg:flex-col justify-between items-center gap-2 order-1 lg:items-start lg:max-w-[215px]'>
                    <Logo className='order-1' stroke='rgba(251, 121, 46, 1)' color='rgba(251, 121, 46, 1)' />
                    <span className='text order-3 lg:order-2 w-full'>{t("FOOTER.TEXT")}</span>
                    {/* <Linkedin className='order-2 lg:order-3' /> */}
                </div>
                <ul className='flex lg:flex-col first-list gap-6 order-2 lg:justify-end lg:mb-3 lg:items-start'>
                    <li><HashLink to={'/#services'}>{t("FOOTER.FLIST.SERVICES")}</HashLink></li>
                    <li><HashLink to={'/#we'}>{t("FOOTER.FLIST.ABOUT")}</HashLink></li>
                    <li><HashLink onClick={handleClick}>{t("FOOTER.FLIST.CONTACT")}</HashLink></li>
                </ul>
                <div className='h-[1px] bg-[#EBE9E9] lg:hidden order-4'></div>
                {/* <ul className='flex lg:flex-col last-list gap-6 order-5 lg:order-3 lg:justify-center'>
                    <li>{t("FOOTER.LLIST.CONFIDENTIAL")}</li>
                    <li>{t("FOOTER.LLIST.POLICIES")}</li>
                </ul> */}
                <EmailForm className='order-3 lg:order-4 justify-start lg:justify-center lg:flex lg:flex-col lg:gap-4 lg:-mb-3' />
            </div>
            <span className='copyright'>{t("FOOTER.COPYRIGHT")}</span>
        </footer>
    );
}

export default Footer;