import './ServiceExtended.scss';
import Card1 from '../../../../assets/images/card-1.png';
import Card2 from '../../../../assets/images/card-2.png';
import Card3 from '../../../../assets/images/card-3.png';
import Card4 from '../../../../assets/images/card-4.png';
import Card5 from '../../../../assets/images/card-5.png';
import ExtendedCard from '../../../../components/extendedCard/ExtendedCard';
import { useTranslation } from 'react-i18next';
import LeftTitle from '../../../../components/leftTitle/LeftTitle';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ServiceExtended = () => {
    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const scrollToSection = (id, sectionId) => {
            const section = document.getElementById(sectionId);
            const element = document.getElementById(id);
            if (section && element) {
                if (id === 'card1') {
                    const sectionTop = section.getBoundingClientRect().top + window.pageYOffset;
                    window.scrollTo({
                        top: sectionTop,
                        behavior: 'smooth',
                    });
                } else {
                    const elementRect = element.getBoundingClientRect();
                    const absoluteElementBottom = elementRect.bottom + window.pageYOffset;
                    window.scrollTo({
                        top: absoluteElementBottom - window.innerHeight,
                        behavior: 'smooth',
                    });
                }
            }
        };

        if (location.hash) {
            setTimeout(() => {
                scrollToSection(location.hash.substring(1), 'cardSection');
            }, 50);
        }
    }, [location]);

    return (
        <div id='cardSection' className="service-extended flex flex-col px-6 py-12 lg:px-[120px] lg:py-[100px] light-bg" key={location.key}>
            <LeftTitle text={t("DETAIL.SERVICES.LEFT")} />
            <span className="title mt-6 mb-6 lg:mt-[60px] lg:mb-[72px]">{t("DETAIL.SERVICES.TITLE")}</span>
            <ExtendedCard id={'card1'} title={t("DETAIL.SERVICES.CARD.TITLE")} text={t("DETAIL.SERVICES.CARD.TEXT")} text2={t("DETAIL.SERVICES.CARD.TEXT2")} image={Card1} />
            <ExtendedCard id={'card2'} title={t("DETAIL.SERVICES.CARD2.TITLE")} text={t("DETAIL.SERVICES.CARD2.TEXT")} text2={t("DETAIL.SERVICES.CARD.TEXT2")} image={Card2} />
            <ExtendedCard id={'card3'} title={t("DETAIL.SERVICES.CARD3.TITLE")} text={t("DETAIL.SERVICES.CARD3.TEXT")} image={Card3} />
            <ExtendedCard id={'card4'} title={t("DETAIL.SERVICES.CARD4.TITLE")} text={t("DETAIL.SERVICES.CARD4.TEXT")} image={Card4} />
            <ExtendedCard id={'card5'} title={t("DETAIL.SERVICES.CARD5.TITLE")} text={t("DETAIL.SERVICES.CARD5.TEXT")} image={Card5} />
        </div>
    )
}

export default ServiceExtended;
