import './Technology.scss';
import LeftTitle from '../../../../components/leftTitle/LeftTitle';
import { useTranslation } from 'react-i18next';
import Tech from '../../../../assets/images/tech-1.png';
import Tech2 from '../../../../assets/images/tech-2.png';
import Tech3 from '../../../../assets/images/tech-3.png';
import Tech4 from '../../../../assets/images/tech-4.png';
import Tech5 from '../../../../assets/images/tech-5.png';
import Tech6 from '../../../../assets/images/tech-6.png';
import Tech7 from '../../../../assets/images/tech-7.png';
import Tech8 from '../../../../assets/images/tech-8.png';
import Tech9 from '../../../../assets/images/tech-9.png';
import Tech10 from '../../../../assets/images/tech-10.png';
import Tech11 from '../../../../assets/images/tech-11.png';
import Button from '../../../../components/button/Button';
import { ReactComponent as BowBg } from '../../../../assets/images/bow-bg.svg';
import { openModal } from '../../../../features/formState/FormState';
import { useDispatch } from 'react-redux';

const Technology = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(openModal());
    };

    return (
        <div id={'saas'} className='technology dark-bg'>
            <div className='tech-div'>
                <LeftTitle text={t("DETAIL.TECHNOLOGY.LEFT")} color='rgba(255, 255, 255, 1)' />
                <span className='title'>{t("DETAIL.TECHNOLOGY.TITLE")}</span>
                <span className='subtitle' dangerouslySetInnerHTML={{ __html: t("DETAIL.TECHNOLOGY.SUBTITLE") }} />
                <div className='flex items-center gap-3'>
                    <img src={Tech} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.1")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech2} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.2")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech3} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.3")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech4} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.4")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech5} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.5")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech6} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.6")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech7} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.7")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech8} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.8")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech9} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.9")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech10} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.10")}</spam>
                </div>
                <div className='flex items-center gap-3'>
                    <img src={Tech11} alt='technology' />
                    <spam className='text'>{t("DETAIL.TECHNOLOGY.TEXT.11")}</spam>
                </div>
                <span className='contact' >{t("DETAIL.TECHNOLOGY.CONTACT")}</span>
                <Button onClick={handleClick} maxWidth='max-w-[161px]' text={t("DETAIL.TECHNOLOGY.BUTTON")} />
                <BowBg className='bow-bg' />
            </div>
        </div>
    );

}

export default Technology;