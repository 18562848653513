import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Channel': 'LOOYTI'
    },
});

export const fetchData = async (url, config = {}) => {
    try {
        const response = await axiosInstance.get(url, config);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const postData = async (url, data = {}) => {
    try {
        const response = await axiosInstance.post(url, data);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
};