import './ExtendedCard.scss';

const ExtendedCard = ({ title, text, image, text2, id }) => {


    return (
        <div id={id} className="extended-card">
            <img src={image} alt="extended-card" />
            <div className="flex flex-col gap-4">
                <span className='title'>{title}</span>
                <span className='text'>{text}</span>
                {text2 && <span className='text pt-4'>{text2}</span>}
            </div>
        </div>
    );
}

export default ExtendedCard;
