import React, { useState, useEffect } from 'react';
import './Header.scss';
import { slide as Menu } from 'react-burger-menu'
import { ReactComponent as BurgerLogo } from '../../assets/images/burger-logo.svg';
import BurgerCross from '../../assets/images/burger-cross.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import Metal from '../../assets/images/card-2.png';
import Service from '../../assets/images/ic-service.png';
import About from '../../assets/images/ic-about.png';
import Contact from '../../assets/images/ic-contact.png';
import { HashLink } from 'react-router-hash-link';
import { openModal } from '../../features/formState/FormState';
import { useDispatch } from 'react-redux';

const Header = () => {
    const [isLight, setIsLight] = useState(false);
    const dispatch = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [shouldBlur, setShouldBlur] = useState(true);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
        setShouldBlur(false);
    }

    const handleMenuClose = () => {
        setIsMenuOpen(false);
        setTimeout(() => {
            setShouldBlur(true);
        }
            , 450);
    }

    const handleClick = () => {
        dispatch(openModal());
        handleMenuClose()
    };

    const handleScroll = () => {
        const divs = document.querySelectorAll('.light-bg, .dark-bg');
        let isLightBackground = false;

        divs.forEach(div => {
            const rect = div.getBoundingClientRect();
            let headerHeight;
            let buffer;

            if (window.innerWidth >= 768) {
                headerHeight = 60.5;
                buffer = 60.5;
            } else {
                headerHeight = 15.5;
                buffer = 15.5;
            }

            if (rect.top <= headerHeight + buffer && rect.bottom >= headerHeight) {
                if (div.classList.contains('light-bg')) {
                    isLightBackground = true;
                }
            }
        });

        setIsLight(isLightBackground);
    };




    useEffect(() => {
        handleScroll();

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <header style={{ backdropFilter: `${!shouldBlur ? 'none' : 'blur(8px)'}` }} className={`header ${isLight ? 'light' : ''}`}>
            <div className='logo'>
                <HashLink to={'/#top'}><Logo /></HashLink>
            </div>
            <Menu isOpen={isMenuOpen} onClose={handleMenuClose} onOpen={handleMenuOpen} className='burger-menu' customCrossIcon={<img alt='BurgerCross' src={BurgerCross} />} customBurgerIcon={<BurgerLogo className='burger-logo' />} right>
                <div className='menu-list'>
                    <div className='flex flex-col'>
                        <img width={64} height={64} className='mb-3' src={Metal} alt="Logo" />
                        <Logo color='#FFFFFF' stroke='#FFFFFF' width={64} height={34} alt="Logo" />
                    </div>
                    <div className='!flex items-center gap-2 self-start'>
                        <img width={24} height={24} src={Service} alt="Logo" />
                        <HashLink scroll={el => scrollWithOffset(el)} onClick={handleMenuClose} to={'/#services'} className="menu-item">Hizmetlerimiz</HashLink>
                    </div>
                    <div className='!flex items-center gap-2 self-start'>
                        <img width={24} height={24} src={About} alt="Logo" />
                        <HashLink onClick={handleMenuClose} to={'/#we'} id="home" className="menu-item">Hakkımızda</HashLink>
                    </div>
                    <div className='!flex items-center gap-2 self-start'>
                        <img width={24} height={24} src={Contact} alt="Logo" />
                        <HashLink onClick={handleClick} className="menu-item">İletişim</HashLink>
                    </div>
                </div>
            </Menu>
            <ul className='header-list hidden lg:flex'>
                <li><HashLink to={'/#we'}>Hakkımızda</HashLink></li>
                <li><HashLink to={'/#services'}>Hizmetlerimiz</HashLink></li>
                <li><HashLink onClick={handleClick}>İletişim</HashLink></li>
            </ul>
        </header>
    );
}

export default Header;
