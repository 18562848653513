import './LeftTitle.scss';

const LeftTitle = ({ text, color='rgba(81, 81, 81, 1)' }) => {
    return (
        <span style={{color: color}} className="left-title">
            {text}
        </span>
    );
}

export default LeftTitle;