import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import './EmailForm.scss';
import Arrow from '../../assets/images/button-arrow.png';
import { useTranslation } from 'react-i18next';
import { postData } from '../../utils/api';

const EmailForm = ({ className }) => {

  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(null);

  const initialValues = {
    email: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email gerekli'),
  });

  const handleSubmit = async (data) => {
    try {
      data.topic = 'NEWSLETTER';
      const res = await postData("/email_subscriptions", data);
      if (res.data.success === true) {
        setIsSuccess(true);
      }
      else {
        setIsSuccess(false);
      }

    } catch (error) {
      console.log(error);
    }
  }

  return (
    isSuccess ?
      <span className='order-3 content-center mail-success'>Teşekkürler! Abone oldunuz.</span>
      :
      <div className={`email-form ${className}`}>
        <span className='mail-label'>{t("FOOTER.MAIL.LABEL")}</span>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className='email-form'>
              <div>
                <Field
                  type="email"
                  name="email"
                  placeholder={"E-posta adresiniz..."}
                  className="email-input"
                />
                {/* <ErrorMessage name="email" component="div" className="error" /> */}
              </div>
              <button className='submit-button' type="submit" disabled={isSubmitting}>
                <img src={Arrow} alt='arrow' />
              </button>
            </Form>
          )}
        </Formik>
      </div>
  );
};

export default EmailForm;
