import Tic from '../../assets/images/tic.png';
import './FormModal.scss';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Button from '../button/Button';
import { ReactComponent as CloseIcon } from '../../assets/images/modal-close.svg';
import Card2 from '../../assets/images/card-2.png';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../features/formState/FormState';
import { postData } from '../../utils/api';

const FormModal = () => {
    const isOpen = useSelector((state) => state.formState.isOpen);
    const dispatch = useDispatch();
    const [isSuccess, setIsSuccess] = useState(false);

    if (!isOpen) return null;

    const handleClose = () => {
        dispatch(closeModal());
        setIsSuccess(false);
    };

    const handleSubmit = async (data) => {
        try {
            const res = await postData("/contact", data);
            if (res.data.success === true) {
                setIsSuccess(true);
            }
            else {
                setIsSuccess(false);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required('Tam ad gerekli')
            .matches(/^[a-zA-ZçÇğĞıİöÖşŞüÜ]+\s[a-zA-ZçÇğĞıİöÖşŞüÜ]+.*$/, 'Tam ad en az iki kelime olmalı'),
        email: Yup.string()
            .email('Geçersiz e-posta adresi')
            .required('E-posta gerekli'),
        phone: Yup.string()
            .required('Telefon gerekli')
            .matches(/^\+?[0-9]{10,15}$/, 'Geçerli bir telefon numarası girin'),
        title: Yup.string()
            .required('Ünvan gerekli')
            .min(2, 'Ünvan en az iki karakter olmalı'),
        sector: Yup.string()
            .required('Sektör gerekli')
            .min(2, 'Sektör en az iki karakter olmalı'),
        companyName: Yup.string()
            .required('Şirket adı gerekli')
            .min(2, 'Şirket adı en az iki karakter olmalı'),
        message: Yup.string()
            .required('Mesaj gerekli')
            .min(2, 'Mesaj en az iki karakter olmalı'),
        // terms: Yup.boolean()
        //     .oneOf([true], 'Şartların kabulü gerekli'),
        // terms2: Yup.boolean()
        //     .oneOf([true], 'Şartların kabulü gerekli')
    });

    return (
        <div>
            <Modal closeIcon={<CloseIcon />} open={isOpen} onClose={handleClose} center>
                <div className='flex flex-col gap-5 lg:gap-0 lg:bg-[#141414] max-w-[417px] lg:pt-10 lg:pb-10 lg:pr-5 lg:pl-10'>
                    <div className='hidden lg:flex flex-col lg:order-4 max-w-[324px] ml-auto mr-auto mb-[43px] image-bg'>
                        <img width={162} height={162} src={Card2} alt='card' />
                        <Logo width={162} height={89} fill='#FFFFFF' color='#FFFFFF' />
                    </div>
                    <span className='l-title hidden lg:block lg:order-1'>İletişim</span>
                    {(isSuccess && window.innerWidth <= 768) ?
                        <></>
                        :
                        <>
                            <span className='title order-1 lg:order-2'>Bize Ulaşın!</span>
                            <div className='flex flex-col gap-[14px] order-2 lg:order-5 lg:gap-0'>
                                <span className='desc order-2'>Bize mail adresimiz üzerinden de ulaşabilirsiniz.</span>
                                <a href='mailto:info@looyti.com' className='mail order-3'>info@looyti.com</a>
                            </div>
                            <span className='text order-4 lg:order-3'>Looyti hizmetleri ile ilgili daha detaylı bilgi almak için bizimle iletişime geçin.</span>
                        </>
                    }
                </div>
                {
                    !isSuccess ?
                        <Formik
                            initialValues={{
                                fullName: '',
                                email: '',
                                phone: '',
                                title: '',
                                sector: '',
                                companyName: '',
                                message: '',
                                // terms: false,
                                // terms2: false
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, errors, touched }) => (
                                <Form className='form'>
                                    <div className='flex flex-col xl:flex-row gap-0 lg:gap-[17px]'>
                                        <div className='flex flex-col gap-[3px]'>
                                            <label htmlFor="fullName">Ad Soyad*</label>
                                            <Field className={errors.fullName && touched.fullName ? 'input-error' : ''} type="text" name="fullName" />
                                            <ErrorMessage name="fullName" component="div" />
                                        </div>
                                        <div className='flex flex-col gap-[3px]'>
                                            <label htmlFor="email">E-Posta*</label>
                                            <Field className={errors.email && touched.email ? 'input-error' : ''} type="email" name="email" />
                                            <ErrorMessage name="email" component="div" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col xl:flex-row gap-0 lg:gap-[17px]'>
                                        <div className='flex flex-col gap-[3px]'>
                                            <label htmlFor="phone">Telefon Numarası*</label>
                                            <Field className={errors.phone && touched.phone ? 'input-error' : ''} type="text" name="phone" inputMode="numeric"
                                                pattern="[0-9+]*"
                                                onKeyPress={(e) => {
                                                    if (!/[0-9+]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }} />
                                            <ErrorMessage name="phone" component="div" />
                                        </div>
                                        <div className='flex flex-col gap-[3px]'>
                                            <label htmlFor="title">Ünvan*</label>
                                            <Field className={errors.title && touched.title ? 'input-error' : ''} type="text" name="title" />
                                            <ErrorMessage name="title" component="div" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col xl:flex-row gap-0 lg:gap-[17px]'>
                                        <div className='flex flex-col gap-[3px]'>
                                            <label htmlFor="sector">Sektör*</label>
                                            <Field className={errors.sector && touched.sector ? 'input-error' : ''} type="text" name="sector" />
                                            <ErrorMessage name="sector" component="div" />
                                        </div>
                                        <div className='flex flex-col gap-[3px]'>
                                            <label htmlFor="companyName">Şirket Adı*</label>
                                            <Field className={errors.companyName && touched.companyName ? 'input-error' : ''} type="text" name="companyName" />
                                            <ErrorMessage name="companyName" component="div" />
                                        </div>
                                    </div>
                                    <div className='flex flex-col gap-[3px]'>
                                        <label htmlFor="message">Mesaj*</label>
                                        <Field className={errors.message && touched.message ? 'input-error' : ''} as="textarea" name="message" />
                                        <ErrorMessage name="message" component="div" />
                                    </div>
                                    {/* <div className='flex flex-col gap-[3px] lg:gap-4 lg:mb-3'>
                                <div>
                                    <label className='flex items-center gap-2'>
                                        <Field type="checkbox" name="terms" />
                                        <span>
                                            I agree to the terms and conditions
                                        </span>
                                    </label>
                                    <ErrorMessage name="terms" component="div" />
                                </div>
                                <div>
                                    <label className='flex items-center gap-2'>
                                        <Field type="checkbox" name="terms2" />
                                        <span>
                                            I agree to the terms and conditions
                                        </span>
                                    </label>
                                    <ErrorMessage name="terms" component="div" />
                                </div>
                            </div> */}
                                    <div className='flex flex-col gap-[3px]'>
                                        <Button type="submit" text='Gönder' disabled={isSubmitting} />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        :
                        <div className='flex flex-col lg:gap-5 gap-4 m-auto items-center'>
                            <img alt='Tic' className='lg:w-[106px] w-[53px]' src={Tic} />
                            <span className='success-title'>Mesajınız bize ulaştı!</span>
                            <span className='mt-2 lg:mt-1 success-text text-nowrap'>En kısa sürede sizinle iletişime geçeceğiz.</span>
                            <span className='-mt-4 lg:-mt-5 success-text'>Teşekkürler!</span>
                        </div>
                }
            </Modal >
        </div >
    );
}

export default FormModal;
